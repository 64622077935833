import React, { useEffect, useState } from 'react';
import Logo from '../../Logo.png'
import { login, resetScorePublicUserDocument, updateUserDocument } from '../../firebase';
import { imageDb } from '../../firebase';
import { getDownloadURL, listAll, ref, uploadBytes, getBlob } from 'firebase/storage';

export const ImageUploader = () => {

  const [image, setImage] = useState('')
  const [previewImage, setPreviewImage] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const upload = async () => {
    try {
      setSubmitted('In Progress ...')
      const imageRef = ref(imageDb, 'photos/activePhoto')
      await uploadBytes(imageRef, image)
      const url = await getDownloadURL(imageRef)
      await updateUserDocument(url)
      setSubmitted('Upload Success')
      await resetScorePublicUserDocument()
    } catch {
      setSubmitted('Failed')
    }
  }

  useEffect(() => {
    if (!image) {
      setSubmitted('Failed')
      return
    }

    const objectUrl = URL.createObjectURL(image)
    setPreviewImage(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [image])

  return (
    <>
      <div style={styles.container} className='Card-Shadow'>
        <img
          src={Logo}
          style={styles.logo}
        />
        <div style={styles.logoTitle}> / Memeber</div>
        <img src={previewImage} alt="No Image" id="img" style={styles.image} />
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          style={styles.selectImage}
        >
        </input>
        { submitted !== 'Failed' ? <div style={styles.progress}>{submitted}</div> : <button onClick={upload} style={styles.submitImage}>upload</button>}
      </div>
    </>
  )
}

export default ImageUploader

const styles = {
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '95%',
    height: '95%',
    maxWidth: 405,
    maxHeight: 600,
    borderRadius: 12,
  },
  logo: {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: 185,
    height: 35,
    top: -32
  },
  logoTitle: {
    zIndex: 1,
    position: 'absolute',
    left: 185,
    width: 185,
    height: 35,
    top: -24,
    fontSize: 16,
    color: 'lightgray',
    fontWeight: '500'
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: 405,
    maxHeight: 540,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
  selectImage: {
    position: 'absolute',
    bottom: 21,
    left: 10,
    color: 'blue',
  },
  submitImage: {
    position: 'absolute',
    right: 10,
    bottom: 20,
  },
  progress: {
    color: 'green',
    position: 'absolute',
    right: 10,
    bottom: 23,
    fontSize: 12
  }
};
