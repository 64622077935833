import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCGluNK5OQ6daYJhLiNTtoHx2u0oykt0yQ",
    authDomain: "ratemymeal-64d95.firebaseapp.com",
    projectId: "ratemymeal-64d95",
    storageBucket: "ratemymeal-64d95.appspot.com",
    messagingSenderId: "472383072914",
    appId: "1:472383072914:web:c6d4516a87e90eb475944b",
    measurementId: "G-3GE6FRLPNH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
export async function initializeApplication() {
    initializeApp(firebaseConfig);
}

export const imageDb = getStorage(app)

export async function login(email, password) {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return true;
    } catch {
        return false;
    }
}

export const getUserDocument = async () => {
  try {
    const db = getFirestore();

    const docRef = doc(db, "photos", `todaysImage`);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    return data.imageUrl
  } catch {
    return "https://firebasestorage.googleapis.com/v0/b/ratemymeal-64d95.appspot.com/o/photos%2FactivePhoto?alt=media&token=7cd7810f-aa53-45e5-966b-e04d8923fb23"
  }
  // 

  // onSnapshot(doc(db, "photos", "todaysImage"), (doc) => {
  //     console.log("Current data: ", doc.data());
  //     const data = doc.data();
  //     return data?.imageUrl
  // });
}


export const updateUserDocument = async (imageUrl) => {
  if (!auth?.currentUser) return;
  const db = getFirestore();

  const docRef = doc(db, "photos", `todaysImage`);
  const docSnap = await getDoc(docRef);

  // If the user has a document already
  try {
    if (docSnap.exists()) {
      await updateDoc(doc(db, "photos", 'todaysImage'), {
        email: `${auth?.currentUser?.email}`,
        imageUrl: imageUrl
      });
    }
  } catch {
    console.error("faild to update");
  }
};

export const updatePublicUserDocument = async (score) => {
  const db = getFirestore();

  const docRef = doc(db, "ratings", `scores`);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();

  try {
    await setDoc(doc(db, "ratings", 'scores'), {
      scores: [score.toString(), ...data?.scores]
    });
  } catch {
    console.error("faild to update");
  }
};

export const resetScorePublicUserDocument = async () => {
  const db = getFirestore();

  try {
    await setDoc(doc(db, "ratings", 'scores'), {
      scores: []
    });
  } catch {
    console.error("faild to update");
  }
};

export const getPublicUserDocument = async (score) => {
  const db = getFirestore();

  const docRef = doc(db, "ratings", `scores`);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data?.scores
};
