import logo from './logo.svg';
import './App.css';
import Card from './Components/Card';
import LoginMember from './Screens/Members/LoginMember'
// import { Switch, Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getUserDocument } from './firebase';
function App() {
  const [newUrl, setNewUrl] = useState(false);

  useEffect(() => {
      newImage()
      getUserDocument().then((users) => setNewUrl(users));    
  }, [])

  const metaTag = document.querySelector('meta[name="theme-color"]');
  metaTag.setAttribute("content", "rgb(243, 244, 246)");

  

  const newImage = async () => {
    const url = await getUserDocument()
    setNewUrl(url)
  }

  return (
    <div className="App">
      <div className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Card newUrl={newUrl}/>} />
            <Route index element={<Card newUrl={newUrl}/>} />
            <Route exact path="/member" element={<LoginMember />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
