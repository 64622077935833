import React, { useEffect, useState } from 'react';
import { getPublicUserDocument } from '../firebase';

export const ResultContent = ({ value }) => {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    fetchScores()
  }, [])

  const fetchScores = async () => {
    const allScores = await getPublicUserDocument()
    setScores(allScores)
  }

  const getAverage = () => {
    const sum = scores.reduce((a, v) => a = parseInt(a) + parseInt(v), 0)
    const average = sum / (scores.length)
    return !average ? 0 : average.toFixed(2)
  }

  const getScoreBrackets = () => {
    const One = scores.filter((a) => a < 10, 0)
    const Two = scores.filter((a) => a < 20 && a > 9, 0)
    const Three = scores.filter((a) => a < 30 && a > 19, 0)
    const Four = scores.filter((a) => a < 40 && a > 29, 0)
    const Five = scores.filter((a) => a < 50 && a > 39, 0)
    const Six = scores.filter((a) => a < 60 && a > 49, 0)
    const Seven = scores.filter((a) => a < 70 && a > 59, 0)
    const Eight = scores.filter((a) => a < 80 && a > 69, 0)
    const Nine = scores.filter((a) => a < 90 && a > 79, 0)
    const Ten = scores.filter((a) => a > 89, 0)

    return (
      <center>
        <table
          style={{
            position: 'absolute',
            textAlign: 'center',
            top: "50%",
            left: "50%",
            width: '80%',
            height: '80%',
            transform: "translate(-50%, -50%)",
          }}>
          <tr>
            <th style={{color: '#74B72E'}}>Range</th>
            <th style={{ color: '#74B72E'}}>Amount</th>
          </tr>
          <tr>
          </tr>
          <tr>
            <td>0-9</td>
            <td>{One.length}</td>
          </tr>
          <tr>
            <td>10-19</td>
            <td>{Two.length}</td>
          </tr>
          <tr>
            <td>20-29</td>
            <td>{Three.length}</td>
          </tr>
          <tr>
            <td>30-39</td>
            <td>{Four.length}</td>
          </tr>
          <tr>
            <td>40-49</td>
            <td>{Five.length}</td>
          </tr>
          <tr>
            <td>50-59</td>
            <td>{Six.length}</td>
          </tr>
          <tr>
            <td>60-69</td>
            <td>{Seven.length}</td>
          </tr>
          <tr>
            <td>70-79</td>
            <td>{Eight.length}</td>
          </tr>
          <tr>
            <td>80-89</td>
            <td>{Nine.length}</td>
          </tr>
          <tr>
            <td>90-100</td>
            <td>{Ten.length}</td>
          </tr>
        </table>
      </center>
    )
  }

  return (
    <>
    <div style={{
      position: "absolute",
      top: -25,
      right: 25,
      fontWeight: '700'
    }}>{!value ? 0 : value}/100</div>
      <div
        style={{
            position: "absolute",
            top: 20,
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: '#6495ED',
            fontWeight: '700'
        }}
      >Average: {getAverage()}</div>
      <div>{getScoreBrackets()}</div>
    </>
  )
}

export default ResultContent

const styles = {};