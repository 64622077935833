import React, { useEffect, useState } from 'react';
import SendButton from '../Send.png'
import Results from '../Results.png'
import browser from '../Android.png'
import Logo from '../Logo.png'
import Sheet from 'react-modal-sheet';
import { updatePublicUserDocument } from '../firebase';
import { ResultContent } from './ResultContent';

export const Card = ({ newUrl }) => {
  const [value, setValue] = useState(75);
  const [submittedValue, setSubmittedValue] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const submit = async () => {
    setSubmitted(true)
    updatePublicUserDocument(value)

    setTimeout(function () {
      setSubmittedValue(value)
      setOpen(true)
    }, 500);
  }

  return (
    <>
      <div style={styles.container} className='Card-Shadow'>
        <img
          src={Logo}
          style={styles.logo}
        />
        <img
          src={newUrl ? newUrl : browser}
          style={styles.image}
        />

        <div>
          <input
            type="range"
            min="0"
            disabled={submitted}
            onChange={(e) => setValue(e.target.value)}
            style={{
              ...styles.input,
              opacity: submitted ? 0.5 : 1
            }}
            value={value}
          />
          <div
            style={{
              ...styles.text,
              opacity: submitted ? 0.3 : 1
            }}>{value} / 100</div>
          <button onClick={submit} disabled={submitted}>
            <div
              style={{
                ...styles.send,
                opacity: submitted ? 0.2 : 1
              }}>Send</div>

            <img
              src={SendButton}
              alt="Send Score"
              style={{
                ...styles.icon,
                opacity: submitted ? 0.2 : 1
              }}
            />
          </button>;
        </div>

        <button onClick={() => setOpen(true)}>
          <img
            src={Results}
            alt="Send Score"
            style={styles.results}
          />
        </button>

        <div>
          <Sheet isOpen={isOpen} onClose={() => setOpen(false)}
            style={{
              maxWidth: 405,
              maxHeight: 600,
              position: "absolute",
              top: "50%",
              left: "50%",
              width: '95%',
              height: '95%',
              transform: "translate(-50%, -50%)",
              borderRadius: 12,
            }}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content><ResultContent value={submittedValue} /></Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </div>
      </div>
    </>
  )
}

export default Card

const styles = {
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '95%',
    height: '95%',
    maxWidth: 405,
    maxHeight: 600,
    borderRadius: 12,
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: 405,
    maxHeight: 540,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
  input: {
    position: 'absolute',
    width: 125,
    bottom: 21,
    left: 10,
  },
  icon: {
    position: 'absolute',
    right: 10,
    width: 40,
    height: 40,
    bottom: 14,
  },
  text: {
    color: '#1AA7EC',
    position: 'absolute',
    bottom: 20,
    left: 145,
    fontSize: 20,
  },
  send: {
    color: '#1AA7EC',
    position: 'absolute',
    right: 50,
    bottom: 20,
    fontSize: 20,
  },
  results: {
    position: 'absolute',
    right: 5,
    top: 5,
    fontSize: 20,
    width: 60,
    height: 60,
  },
  logo: {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: 185,
    height: 35,
    top: -32
  }
};
