import React, { useState } from 'react';
import Logo from '../../Logo.png'
import { login } from '../../firebase';
import { ImageUploader } from './ImageUploader';

export const LoginMember = () => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [authenticated, setAuthenticated] = useState(false)

  const submit = async () => {
    const authenticated = await login(`${username}@ratemymeal.co.uk`, password);
    if (authenticated) {
      setAuthenticated(authenticated)
      setMessage('')
    } else {
      setMessage('Invalid Credentials')
    }
  }
  return authenticated ? <ImageUploader /> : (
    <>
      <div style={styles.container} className='Card-Shadow'>
        <img
          src={Logo}
          alt="Send Score"
          style={styles.logo}
        />
        <div style={styles.logoTitle}> / Memeber</div>
        <center>
          <div style={styles.title}>Authentication</div>
          <input
            min="0"
            placeholder='Username'
            onChange={(e) => setUsername(e.target.value.toLowerCase())}
            style={styles.input}
            value={username}
          />
          <br />
          <input
            type='password'
            min="0"
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            value={password}
          />
          <br />
          <center>
            <button
              onClick={submit}
              style={{
                ...styles.button,
                backgroundColor: username && password ? '#6495ED' : 'lightgray',
                color: username && password && 'lightgray',
              }}
            > Login </button>;
            <div style={styles.message}>{message}</div>
          </center>
        </center>
      </div>
    </>
  )
}

export default LoginMember

const styles = {
  container: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '95%',
    height: '95%',
    maxWidth: 405,
    maxHeight: 600,
    borderRadius: 12,
  },
  title: {
    color: '#74B72E',
    marginTop: 40,
    marginBottom: 10,
    fontSize: 28
  },
  input: {
    marginBottom: 15,
    width: 160,
    height: 24,
    paddingLeft: 5,
    borderRadius: 4,
    borderColor: 'lightgray',
    borderWidth: 2,
    fontWeight: '600',
    color: '#6495ED',
  },
  button: {
    paddingLeft: 5,
    marginLeft: 7,
    borderRadius: 4,
    borderColor: 'lightgray',
    borderWidth: 0,
    width: 150,
    height: 25,
    fontWeight: '600'
  },
  logo: {
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    width: 185,
    height: 35,
    top: -32
  },
  logoTitle: {
    zIndex: 1,
    position: 'absolute',
    left: 185,
    width: 185,
    height: 35,
    top: -24,
    fontSize: 16,
    color: 'lightgray',
    fontWeight: '500'
  },
  message: {
    color: 'red',
    fontSize: 10,
    paddingTop: 10,
  },
};
